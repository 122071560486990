<template>
    <el-dialog title="添加底部说明" :visible="showAddBottomConfig" width="504px" class="dialog-vertical" @close="closeAdd">
        <el-form :model="configForm" size="medium" label-width="100px" :rules="form_rules" ref="configForm">
            <el-form-item label="类型">
                <el-radio v-for="(radio,index) in tpye_list" v-model="configForm.type" :label="radio.value" :key="index">{{radio.name}}</el-radio>
            </el-form-item>
            <el-form-item v-if="configForm.type == 1" prop="content">
                <el-input v-model="configForm.content" maxlength="20" placeholder="请填写底部说明，不超过20字"></el-input>
            </el-form-item>
            <el-form-item v-if="configForm.type == 0">
              <SingleMediaWall ratio="776:40" v-model="configForm.content" fit="contain" :width="100" :height="100" :borderRadius="4">
                <p slot="info" class="img-tip">
                  <span class="el-icon-info"></span>建议上传尺寸比例为776*40
                </p>
              </SingleMediaWall>
            </el-form-item>
            <el-form-item label="拨打电话">
                <el-radio v-for="(radio,index) in phone_status" v-model="configForm.status" :label="radio.value" :key="index">{{radio.name}}</el-radio>
            </el-form-item>
            <el-form-item v-if="configForm.status == 1" prop="phone">
                <el-input v-model.number="configForm.phone" maxlength="11" placeholder="请填写手机号码"></el-input>
            </el-form-item>
            <div v-if="configForm.status == 1" style="font-size: 16px;color: rgb(25, 118, 210);margin-top: 20px;margin-left: 100px">界面上触发后将拨打该电话号码</div>
        </el-form>
        <template #footer>
          <el-button size="small" @click="closeAdd">取消</el-button>
          <el-button size="small" type="primary" @click="handleAddConfig('configForm')">确认</el-button>
        </template>
    </el-dialog>
</template>

<script>
    import SingleMediaWall from "../../../common/components/SingleMediaWall";
    export default {
        name: "BottomConfig",
        props:['showAddBottomConfig','editObj','selectIcon'],
        data(){
            return{
                configForm:{
                    type:1,
                    content:'',
                    status:0,
                    phone:''
                },
                tpye_list:[
                    {name:'文字',value:1},
                    {name:'图片',value:0}
                ],
                phone_status:[
                    {name:'关闭',value:0},
                    {name:'开启',value:1}
                ],
              form_rules:{
                content:[
                  { required: true, message: '请填写底部说明', trigger: 'blur' },
                  { max: 20, message: '长度在20个字符内', trigger: 'blur' }
                ],
                phone:[
                  { required: true, message: '请输入电话号码', trigger: 'blur' },
                  { required: true,pattern:/^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/, message: '电话号码输入错误', trigger: 'blur' },
                ]
              }
            }
        },
      components:{SingleMediaWall},
        watch:{
            selectIcon(){
                if(this.selectIcon){
                    this.configForm.content = this.selectIcon
                }
            },
            editObj(){
                if(this.editObj && this.editObj.content){
                    this.configForm = this.editObj
                }
            },
            'configForm.type'(type){
                this.configForm.content = ''
            },
            showAddBottomConfig(){
              if(!this.showAddBottomConfig){
                this.$refs['configForm'].resetFields();
              }
            }
        },
        methods:{
            handleAddConfig(configForm){
                if(this.configForm.content == ''&&this.configForm.type == 0){
                    return this.$message.info("请选择图片");
                }
                this.$refs[configForm].validate((valid) => {
                    if (valid) {
                      let obj = JSON.parse(JSON.stringify(this.configForm))
                      this.$emit('addContent',obj)
                      this.closeAdd()
                    } else {
                      console.log('error submit!!');
                      return false;
                    }
                });
            },
            closeAdd(){
                this.$emit('closeAddBottom')
            },
        }
    }
</script>

<style scoped lang="scss">
    .dialog-bottom{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
    }
    .avatar-uploader  {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        .avatar-uploader :hover {
            border-color: #409EFF;
        }
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
    .img-tip{
        font-size: 14px;
        color: #b2b2b2;
        margin-top: 20px;
    }
</style>
