import api from "../../../base/utils/request";

//获取底部说明配置
export const getCycBottomExplain = data => {
    return api({
        url: '/admin/cyc/Settings/getCycBottomExplainConfig',
        method: 'post',
        data
    })
}
//设置底部说明
export const setCycBottomExplain = data => {
    return api({
        url: '/admin/cyc/Settings/setCycBottomExplainConfig',
        method: 'post',
        data
    })
}