<template>
  <div class="content" v-loading="showLoad">
    <div class="bottom-config">
      <el-table style="width: 100%;margin-bottom: 20px" class="thead-light" stripe :data="bottom_explain"
        id="sortTable" row-key="id">
        <el-table-column prop="id" label="行数" min-width="70px">
          <template slot-scope="scope">
            <span>第{{scope.$index + 1}}行</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" min-width="70px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.type == '0'?'图片':'文字'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" min-width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            <list-image v-if="scope.row.type == '0'" :src="scope.row.content" is-image></list-image>
            <span v-if="scope.row.type == '1'">{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="70px">
          <template slot-scope="scope">
            <list-status-text :text="scope.row.status == '0'?'关闭':'开启'"></list-status-text>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px" fixed="right">
          <template slot-scope="scope">
            <el-button style="color: #3576FF" size="small" type="text"
              @click="handleShowAdd(scope.row,scope.$index)">编辑</el-button>
            <el-button style="color: #3576FF" size="small" type="text" @click="handleDelete(scope.$index)">删除
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="排序" min-width="70px">
          <p class="el-icon-sort cursor-pointer my-handle"></p>
        </el-table-column>
      </el-table>
      <div class="add-bottom" v-if="bottom_explain.length < 3" @click="handleShowAdd">+ 添加一行</div>
    </div>
    <fixed-action-bar>
      <el-button @click="handleSaveConfig" type="primary">保存</el-button>
    </fixed-action-bar>
    <BottomConfig :showAddBottomConfig="showAddBottomConfig" :editObj="editObj" @addContent="addContent"
      @closeAddBottom="showAddBottomConfig = false"></BottomConfig>
  </div>
</template>

<script>
import {
  getCycBottomExplain,
  setCycBottomExplain,
} from "../api/bottom-explain";
import Sortable from "../../page-renovation/js/Sortable.min";
import BottomConfig from "../components/AddOtherBottom/BottomConfig";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import ListStatusText from "../../../base/components/List/ListStatusText";
import ListImage from "../../../base/components/List/ListImage";
export default {
  name: "cyc-bottom-explain",
  data() {
    return {
      bottom_explain: [],
      //修改对象
      editObj: {},
      editIndex: -1,
      showAddBottomConfig: false,
      showLoad: false,
    };
  },
  components: {
    ListImage,
    ListStatusText,
    FixedActionBar,
    BottomConfig,
  },
  created() {
    this.showLoad = true;
    getCycBottomExplain()
      .then((res) => {
        let arr = res.data;
        arr.forEach((item, index) => {
          item.id = index + 1;
          this.bottom_explain.push(item);
        });
        this.showLoad = false;
      })
      .catch((err) => {
        this.showLoad = false;
      });
  },
  mounted() {
    this.drag();
  },
  methods: {
    handleSaveConfig() {
      setCycBottomExplain({ config: this.bottom_explain })
        .then((res) => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        })
        .catch((err) => {});
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = this.bottom_explain; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          this.$nextTick(() => {
            this.bottom_explain = [];
            arr.forEach((item, index) => {
              this.bottom_explain.push(item);
            });
          });
        },
      });
    },
    addContent(data) {
      if (this.editIndex >= 0) {
        this.bottom_explain[this.editIndex] = data;
        this.bottom_explain.splice(this.editIndex, 1, data);
        this.editIndex = -1;
        this.editObj = {};
      } else {
        console.log(data);
        data.id = this.bottom_explain.length + 1;
        this.bottom_explain.push(data);
      }
    },
    handleShowAdd(data, index) {
      if (index >= 0) {
        this.editObj = JSON.parse(JSON.stringify(data));
        this.editIndex = index;
      }
      this.showAddBottomConfig = true;
    },
    handleDelete(index) {
      this.bottom_explain.splice(index, 1);
      this.bottom_explain.forEach((e, i) => {
        this.bottom_explain[i].id = i + 1;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-config {
  //margin-top: 50px;
  .bottom-title {
    font-size: 20px;
    color: rgb(50, 73, 101);
    margin-bottom: 22px;
  }
  .add-bottom {
    margin-bottom: 50px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px dashed rgba(217, 217, 217, 1);
    font-size: 14px;
    color: rgba(70, 128, 255, 1);
    cursor: pointer;
    &:hover {
      border: 1px dashed rgba(70, 128, 255, 1);
    }
  }
}
</style>
